@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-color: white;
}
/* 
html, body {
  cursor: url('./assets/glowing-cursor-2.png'), auto;
} */

